import Footer from 'components/footer'
import Navbar from 'components/navbar'
import Link from 'next/link'

const errorPage = () => {
  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-center bg-cover bg-top bg-no-repeat bg-image404 h-screen">
        <div className="sm:mt-4 sm:mb-4 mt-4 w-full flex-row items-center flex justify-center">
          <h3 className="md:text-6xl text-4xl text-uppercase font-bold text-black tracking-wider">OOPS!!</h3>
        </div>
        <div className="my-4 md:w-1/2 w-full m-auto flex-row items-center flex justify-center">
          <p className="md:text-2xl text-lg text-center text-black">
            The page you are looking for was moved, removed, renamed or never existed.
          </p>
        </div>
        <div className="my-4 md:w-1/2 w-full m-auto flex-row items-center flex justify-center">
          <blockquote className="italic text-center from-black md:text-2xl text-lg font-semibold">
            “Not all those who wander are lost”
            <br /> -J.R.R. Tolkien
          </blockquote>
        </div>
        <Link href="/quiz">
          <button className="bg-themeOrange my-4 py-1 px-4 rounded-xl text-white">Find Your Destination</button>
        </Link>
      </div>
      <Footer />
    </>
  )
}
export default errorPage
